import axios from '@/config/httpConfig'

//上传图片文件
export function uploadImgMany(data) {
  return axios.post('/file/uploadImgMany', data)
}

//获取文件大小限制
export function queryLimit(data) {
  return axios.post('/file/queryLimit', data)
}
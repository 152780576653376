<template>
  <!-- 多文件上传组件 - ABB -->
  <div v-if="isOnLoad">
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="692px"
      :before-close="handleClose"
      append-to-body
    >
      <div class="content" v-loading="uploading">
        <el-upload
          class="left_bar"
          drag
          :action="action"
          multiple
          :disabled="!isSuportUpload"
          :http-request="uploadFile"
          :auto-upload="false"
          :show-file-list="false"
          list-type="picture"
          :on-change="fileChange"
        >
          <!-- 支持上传的盒子 -->
          <div class="suport_upload_box" v-if="isSuportUpload">
            <i class="el-icon-upload"></i>
            <div class="text">拖放图片</div>
            <div class="or">或者</div>
            <div>
              <el-button slot="trigger">点击上传图片</el-button>
            </div>
          </div>
          <!-- 不支持上传的盒子 -->
          <NotSuport v-else :uploadLimit="uploadLimit" />

          <div class="tip_area">
            <div>
              总大小限制为{{ totalLimitFileSize }}MB，或者最多不得超过【{{
                uploadLimit
              }}】个图片
            </div>
            <div>仅支持的格式：JPEG，PNG，GIF和BMP。</div>
          </div>
        </el-upload>

        <div class="right_bar">
          <div class="padding_wrapper">
            <div class="header">
              <span>选择的图片</span>
              <span>{{ thumbList.length }}/{{ uploadLimit }}</span>
            </div>
            <div class="content_slider">
              <div
                class="content_slider_item"
                v-for="item in thumbList"
                :key="item.id"
              >
                <div class="img">
                  <img :src="item.url" :alt="item.name" />
                </div>
                <div class="content_slider_info">
                  <!-- 内容上下文 -->
                  <div class="content_slider_context">
                    <div class="content_slider_info_name">
                      <span>{{ item.name }}</span>
                    </div>
                    <div class="content_slider_info_size">
                      <span>{{ KB2MB(item.size) }}</span>
                    </div>
                  </div>
                  <!-- 删除按钮 -->
                  <div class="delete_icon_wrap">
                    <svg
                      class="icon"
                      aria-hidden="true"
                      @click="deleteItem(item)"
                    >
                      <use href="#icon-system_delete_ic"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <el-progress
            :percentage="progressVal"
            :stroke-width="3"
            :show-text="false"
          ></el-progress>
          <div class="bottom">
            <span
              >总大小限制：{{ totalFileSizeMB }}MB/{{
                totalLimitFileSize
              }}MB</span
            >
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sendRequest">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as api from "./api";

export default {
  props: {
    isShowdialog: {
      type: Boolean,
      default: false,
    },
    //上传数量限制
    uploadLimit: {
      type: Number,
      default: 5,
    },

    // 初始化上传列表
    initUploadThumbList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    NotSuport: () => import("./components/NotSuport.vue"),
  },
  data() {
    return {
      // 是否支持上传
      isSuportUpload: true,
      action: "test",
      fileType: ["png", "jpg", "jpeg", "bmp", "gif"],
      //缩略图预览列表
      thumbList: [],
      //总文件限制大小
      totalLimitFileSize: window.Glod.IMG_FIELDS_FILE_LIMIT_SIZE_MB,
      //当前已经上传的文件大小：单位 MB
      totalFileSizeMB: 0,
      //上传中loading
      uploading: false,
      isOnLoad: false,
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.isShowdialog;
      },
      set(nval) {
        this.$emit("update:isShowdialog", nval);
      },
    },
    progressVal() {
      const totalFileSizeMB = this.totalFileSizeMB;
      const totalLimitFileSize = this.totalLimitFileSize;
      return Number(((totalFileSizeMB / totalLimitFileSize) * 100).toFixed(2));
    },
  },
  methods: {
    /**
     * 代理，只能显示1次
     */
    proxyMessage(e) {
      const messageDom = document.getElementsByClassName("el-message")[0];
      if (messageDom) return;
      return this.$message(e);
    },
    KB2MB(kb) {
      if (kb >= 1024) {
        return (kb / 1024).toFixed(2) + "MB";
      } else {
        return kb + "KB";
      }
    },
    // 字节转KB
    Byte2Kb(byte) {
      return Number((byte / 1024).toFixed(2));
    },

    fileChange(file) {
      const { url, raw, size, name, uid } = file;
      // 文件大小：单位 KB
      let KB = this.Byte2Kb(size);
      /**
       * 选择文件前判断文件大小是否已经超出限制
       */
      // 当前已上传文件大小
      let totalFileSizeMB = this.totalFileSizeMB;
      // 总限制大小
      let totalLimitFileSize = this.totalLimitFileSize;
      // 当前选择的文件大小
      let currentFileSizeMB = Number((size / 1024 / 1024).toFixed(2));
      if (currentFileSizeMB + totalFileSizeMB > totalLimitFileSize) {
        this.proxyMessage({
          type: "error",
          message: `文件总大小超出了允许的限制${totalLimitFileSize}MB。`,
        });
        return;
      }
      // 文件可上传数量控制
      if (this.thumbList.length >= this.uploadLimit) {
        this.proxyMessage({
          message: this.$i18n.t("vue_label_file_num_limit_tip"),
          type: "warning",
        });
        return;
      }
      let type = file.name.slice(file.name.lastIndexOf(".") + 1);
      type = type.toLowerCase();
      // 文件上传格式校验
      if (this.fileType.indexOf(type) === -1) {
        this.proxyMessage({
          message: this.$i18n.t("vue_label_file_wrong_type", { type: type }),
          type: "error",
        });
        return false;
      }

      this.thumbList.push({
        url,
        raw,
        size: KB, //KB
        name,
        isTempUrl: true,
        id: uid,
      });

      this.calculateSize();
    },
    // 计算已选择的文件总大小 单位 MB
    calculateSize() {
      let sumSize = 0;
      this.thumbList.forEach((item) => {
        sumSize += Number(Number(item.size) / 1024);
      });
      this.totalFileSizeMB = Number(sumSize.toFixed(2));
    },
    handleClose(done) {
      done();
      this.dialogVisible = false;
    },
    uploadFile(params) {
      let form = new FormData(); // 创建form对象
      form.append("file", params.file); // 通过append向form对象添加数据
      this.$emit("fileChange", params);
    },
    // 上传文件请求
    async sendRequest() {
      // 没有数据则终止
      if (this.thumbList.length < 1) {
        this.$emit("uploadFileSuccess", {
          data: [],
        });
        return;
      }
      let formData = new FormData();
      // 上传成/编辑时上次的数据
      let data = [];
      this.thumbList.forEach((file) => {
        // 如果不是新选择上传的文件，则不需要添加进formdata中进行上传
        if (file.isTempUrl) {
          formData.append("files", file.raw);
          formData.append("filesName", file.name);
        } else {
          data.push(file);
        }
      });

      // 没有新增的需要上传文件，则不调用接口
      if (formData.get("files")) {
        this.uploading = true;
        let res = await api.uploadImgMany(formData);
        this.uploading = false;
        let resData = res.data || [];
        if (resData.length > 0) {
          // 后台返回的文件大小是字节大小，转成 KB 单位
          resData = resData.map((item) => {
            return {
              name: item.name,
              size: this.Byte2Kb(item.filesize),
              fileid: item.fileid,
              id: item.fileid,
              //  是否是临时路径，追加图片时，不是临时路径要用接口调用回显
              isTempUrl: false,
              type: item.type,
              // 生成预览时或外部使用时的url
              // url: this.generatePath(item.fileid),
            };
          });
          data.push(...resData);
        }
      }
      this.$emit("uploadFileSuccess", {
        data,
      });
    },
    // 删除
    deleteItem(item) {
      this.thumbList.forEach((fileItem, index) => {
        if (fileItem.id === item.id) {
          this.thumbList.splice(index, 1);
        }
      });
      // 删除后更新一下已经上传的文件大小
      this.calculateSize();
    },
    /**
     * 初始化时调用一次，
     * 做编辑时，初始化要展示的数据回显
     */
    initUploadThumbListFN() {
      api.queryLimit({ type: "file" }).then((res) => {
        let size = Number(res.data.slice(0, res.data.length - 2));
        this.totalLimitFileSize = size;
        this.isOnLoad = true;
      });

      this.thumbList.push(...this.initUploadThumbList);
      this.calculateSize();
    },
  },
  mounted() {
    this.initUploadThumbListFN();
  },
  watch: {
    thumbList: {
      handler(nval) {
        if (nval.length >= this.uploadLimit) {
          this.isSuportUpload = false;
        } else {
          this.isSuportUpload = true;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>

<style>
.el-form-item__content {
  line-height: normal;
}
</style>